import React, { Fragment } from "react";
import { Collapse } from "antd";
import "../style.css";
import barcode from "./../../../assets/img/barcode.png";
import price from "./../../../assets/img/price.png";

const { Panel } = Collapse;
const listKeys = ["1", "2", "3", "4", "5", "6", "7", "8"];
export default function TermContent({ open }) {
  return (
    <Fragment>
      <p className="title-rule">
        CHƯƠNG TRÌNH KẾT NỐI THÀNH VIÊN <br />
        NỘI DUNG DÀNH CHO THÀNH VIÊN DnC
      </p>
      <p>
        Nhằm gắn kết và mang đến nhiều quyền lợi cho Quý Khách hàng là Kiến trúc
        sư, nhà Thầu xây dựng trên phạm vi toàn quốc, Nippon Paint (Việt Nam) ra
        mắt Chương trình “Kết nối Thành viên” dành cho thành viên DnC kết nối
        cùng Nhà Thầu DiDiDi, cụ thể như sau:
      </p>
      <Collapse
        bordered={false}
        collapsible={open ? "disabled" : true}
        defaultActiveKey={open ? listKeys : []}
        expandIconPosition="right"
        className="content"
      >
        <Panel
          header={<span className="panel-title">1. Đối tượng tham gia</span>}
          key="1"
        >
          <p>
            Đối tượng tham gia chương trình, sau đây gọi tắt là Thành viên, phải
            đạt đủ các điều kiện sau:
          </p>
          <ol type="a" className="term-list">
            <li>
              Cá nhân kiến trúc sư, nhà thiết kế, kỹ sư xây dựng, nhà thầu xây
              dựng là chủ hoặc đang làm việc tại các công ty thiết kế/xây dựng
              và có liên hệ kết nối trực tiếp với các nhà thầu sơn đã là thành
              viên hợp lệ chương trình “DiDiDi – Đối tác bền lâu” của Nippon
              Paint (Việt Nam). . <br />
              Trong trường hợp số lượng các cá nhân muốn trở thành thành viên
              chương trình nêu trên, đăng ký cùng một đơn vị công tác mà đơn vị
              này đã có hơn 5 thành viên tham gia, Nippon Paint (Việt Nam) có
              quyền từ chối việc đăng ký của các cá nhân tiếp theo.
            </li>
            <li>
              Là thành viên đã đăng ký và kích hoạt thành công tài khoản trên
              ứng dụng Nippon DnC.
            </li>
            <li>
              Cán bộ nhân viên của Nippon Paint, chủ Đại lý, nhân viên Đại lý,
              thành viên chương trình “DiDiDi - Đối tác bền lâu” và/hoặc thành
              viên chương trình “Quét và tích điểm thông qua ứng dụng My Nippon
              Paint” có thẻ thành viên hoạt động không thuộc đối tượng tham gia
              chương trình này.
            </li>
          </ol>
        </Panel>
        <Panel
          header={<span className="panel-title">2. Thời gian áp dụng</span>}
          key="2"
        >
          <p className="mb-1">
            Từ ngày 10/06/2022 đến khi có thông báo kết thúc chương trình của
            Nippon Paint (Việt Nam).
          </p>
        </Panel>
        <Panel
          header={<span className="panel-title">3. Địa điểm áp dụng</span>}
          key="3"
        >
          <p className="mb-1">Trên toàn quốc.</p>
        </Panel>
        <Panel
          header={<span className="panel-title">4. Nội dung chương trình</span>}
          key="4"
        >
          <p className="mb-1">4.1. Khái niệm</p>
          <p>
            Nhà thầu DiDiDi là cá nhân nhà thầu sơn có sử dụng sản phẩm Nippon
            Paint, có lưu giữ hợp lệ mã vạch sản phẩm và có quét mã vạch sản
            phẩm qua ứng dụng DiDiDi.
            <br />
            Thành viên DnC là cá nhân kiến trúc sư, nhà thiết kế, nhà thầu xây
            dựng đạt điều kiện như điều 1, có chỉ định các công trình sử dụng
            sản phẩm Nippon Paint và có kết nối trực tiếp các thành viên chương
            trình “DiDiDi - Đối tác bền lâu”.
          </p>
          <p className="mb-1">4.2. Nội dung</p>
          <ol type="a" className="term-list">
            <li>
              Thành viên DnC, khi kết nối cùng nhà thầu DiDiDi thông qua việc
              quét điểm của nhà thầu trên ứng dụng DiDiDi, sẽ nhận được 30% điểm
              tích lũy cơ bản mà nhà thầu DiDiDi quét được qua mỗi phiên kết
              nối.
            </li>
            <li>
              Nhà thầu DiDiDi sử dụng sản phẩm Nippon Paint (có mã vạch như mẫu
              đính kèm 1), và quét điểm thông qua ứng dụng DiDiDi theo bảng điểm
              Đính kèm 2, kết nối cùng thành viên DnC theo quy trình sau:
              <br />
              <ul>
                <li>
                  Thành viên DnC có tài khoản đang hoạt động sẽ được hiển thị
                  thông tin trên ứng dụng DiDiDi.
                </li>
                <li>
                  Nhà Thầu DiDiDi bắt đầu phiên kết nối bằng cách chọn thành
                  viên DnC kết nối trước khi bắt đầu quét điểm.
                </li>
                <li>
                  Sau khi hoàn tất quét điểm & kết thúc phiên kết nối thông qua
                  việc xác nhận trên ứng dụng DiDiDi, Thành viên DnC sẽ nhận
                  được 30% điểm tích lũy cơ bản nhà thầu DiDiDi đã quét trong
                  phiên kết nối vào tổng điểm kết nối của thành viên.
                </li>
              </ul>
            </li>
            <li>
              Quy cách thưởng:
              <br />
              Điểm kết nối tích lũy được sẽ được cập nhật tự động trên ứng dụng
              Nippon DnC của thành viên khi có giao dịch kết nối thành công.
              <br />
              Số điểm kết nối tích lũy được sẽ được quy đổi thành quà tặng
              Voucher trên ứng dụng Nippon DnC. Thành viên hoàn toàn chủ động
              theo dõi điểm và tiến hành thực hiện đổi quà tặng khi có nhu cầu
              và đủ mức điểm tối thiểu cho quà tặng.
              <ul type="i">
                <li>
                  Trong trường hợp khi đạt mức quy đổi quà tặng, nhưng thành
                  viên chưa muốn quy đổi quà, có thể tiếp tục tích lũy điểm để
                  đổi các phần quà ở mức cao hơn.
                </li>
                <li>
                  Trong vòng 365 ngày kể từ ngày đổi thưởng gần nhất (nếu có),
                  nếu thành viên không có phát sinh bất kỳ giao dịch đổi điểm
                  nào, Nippon Paint (Việt Nam) được quyền đơn phương hủy điểm
                  kết nối chưa đổi thưởng trên hệ thống sau khi thông báo cho
                  Thành viên.
                </li>
                <li>
                  Sau khi thành viên xác nhận đổi quà và đã nhận mã Voucher quà
                  tặng, trách nhiệm hỗ trợ, hướng dẫn về thủ tục, quy trình và
                  những phát sinh tiếp theo do Đối tác cung ứng dịch vụ nền
                  tảng, dịch vụ quà tặng và nhà cung cấp dịch vụ/quà chịu trách
                  nhiệm. Nippon Paint (Việt Nam) không chịu trách nhiệm trực
                  tiếp nhưng cam kết hỗ trợ thành viên tốt nhất để hoàn thành
                  việc nhận quà theo đúng quy định của Chương trình “Kết nối
                  thành viên”.
                </li>
              </ul>
              <span>
                <br />
              </span>
            </li>
          </ol>
        </Panel>
        <Panel
          header={<span className="panel-title">5. Các quy định khác</span>}
          key="5"
        >
          <ol type="a" className="term-list">
            <li>
              Thành viên đồng ý đăng ký bằng CMND/CCCD và cung cấp số điện
              thoại, thông tin cá nhân, thông tin đơn vị công tác, vị trí công
              tác cho Nippon Paint (Việt Nam).
            </li>
            <li>
              Thành viên đồng ý nhận thông tin cập nhật, khuyến mãi, thông báo
              thương mại, giới thiệu sản phẩm và tin tức thông qua ứng dụng
              Nippon DnC.
            </li>
            <li>
              Trong trường hợp thành viên có thay đổi thông tin, thành viên chủ
              động liên hệ đến Nippon Paint (Việt Nam) để cập nhật thông tin của
              thành viên. Trường hợp thành viên khiếu nại không nhận được tiền
              thưởng hoặc các quà tặng khác từ chương trình, Nippon Paint chỉ
              chấp nhận giải quyết các khiếu nại của thành viên khi những khiếu
              nại này phát sinh từ sai sót của chương trình, chúng tôi không
              chịu trách nhiệm nếu quý thành viên cung cấp thông tin không chính
              xác.
            </li>
            <li>
              Thành viên sẽ chịu tất cả các loại thuế phát sinh trong quá trình
              nhận thưởng.
            </li>
            <li>
              Khi có bất kỳ nghi vấn nào về tính hợp lệ của Điểm kết nối, Nippon
              Paint (Việt Nam) có quyền bảo lưu để xem xét và có quyền tạm ngưng
              tính năng quy đổi điểm sang quà tặng/tiền thưởng của thành viên.
              Khi xác định có sự gian lận, không đúng thể lệ chương trình,
              Nippon Paint có toàn quyền được hủy toàn bộ số Điểm kết nối của
              thành viên, đồng thời khai trừ tư cách thành viên.
            </li>
            <li>
              Đây là chương trình khuyến khích thương mại, do đó mọi tranh chấp
              sẽ được ưu tiên xử lý bằng đàm phán trên tinh thần tôn trọng công
              bằng, hợp tác dựa trên đạo đức kinh doanh.
            </li>
            <li>
              Mọi hành vi bao gồm và không loại trừ như thông đồng giữa các
              thành viên DnC và nhà thầu DiDiDi để trục lợi, những hành vi không
              công bằng gây thiệt hại đến kinh tế cho Nippon Paint và các Thành
              viên khác, sẽ bị xem là phạm pháp và sẽ bị khởi kiện đến nhà chức
              trách.
            </li>
          </ol>
        </Panel>
        <Panel
          header={<span className="panel-title">6. Mẫu đính kèm</span>}
          key="6"
        >
          <p>
            Mẫu Đính kèm 1: Mã vạch sản phẩm (được dán trên thùng/lon sơn của
            Nippon Paint)
          </p>
          <p className="text-center">
            <img src={barcode} alt="barcode" />
          </p>
          <p className="mb-0">Mẫu Đính kèm 2: Bảng Điểm sản phẩm</p>
          <p className="text-center">
            <img src={price} style={{ width: "100%" }} alt="price" />
          </p>
        </Panel>
      </Collapse>
    </Fragment>
  );
}

import React, { useState } from "react";
import Header from "../../components/Header";
import "./style.css";
import TermContent from "./components/TermContent";
import Footer from "../../components/Footer";
import { Button } from "antd";
import { useLocation } from "react-router-dom";

export default function TermCondition() {
  const location = useLocation();
  const open = location.pathname === "/term" ? false : true;
  const signalEnding = () => {
    window.ReactNativeWebView.postMessage("END_SIGNAL");
  };

  return (
    <div className="term">
      <Header title="Thể lệ chương trình" />
      <div className="containers">
        <TermContent />
      </div>
      <Footer />
    </div>
  );
}

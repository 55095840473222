import React from "react";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import "./style.css";
const antIcon = (
  <Loading3QuartersOutlined style={{ fontSize: 20, color: "#31a6fb" }} spin />
);

const LoadingComponent = () => {
  return (
    <div className="loading-component">
      <Spin size="large" indicator={antIcon} />
    </div>
  );
};

export default LoadingComponent;

import React, { useState } from "react";
import Header from "../../components/Header";
import "./style.css";
import TermContent from "./components/TermContent";
import Footer from "../../components/Footer";
import { Button } from "antd";
import { useLocation } from "react-router-dom";

export default function TermCondition() {
  const location = useLocation();
  const open = location.pathname === "/term" ? false : true;
  const signalEnding = () => {
    window.ReactNativeWebView.postMessage("END_SIGNAL");
  };

  return (
    <div className="term">
      {!open && <Header title="Điều khoản điều lệ" />}
      <div className="containers">
        <TermContent open={open} />
        {open ? (
          <div className="text-center">
            <Button
              style={{
                backgroundColor: "#e75f3a",
                borderRadius: "10px",
              }}
              onClick={signalEnding}
            >
              <span className="text-white">Đồng ý và bắt đầu</span>
            </Button>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
}

import React from "react";
import Header from "../../components/Header";
import { Collapse } from "antd";
import "./style.css";
import Footer from "./../../components/Footer";
const { Panel } = Collapse;
export default function Questions() {
  return (
    <div className="questions">
      <Header title="Câu hỏi thường gặp" />
      <div className="containers">
        <Collapse
          className="content"
          bordered={false}
          expandIconPosition="right"
        >
          <Panel
            header={
              <span className="panel-title">1. Ứng dụng Nippon DnC là gì?</span>
            }
            key="1"
          >
            <p>
              DnC là ứng dụng chăm sóc khách hàng của Nippon Paint Việt Nam dành
              riêng cho Kiến trúc sư, Kỹ sư xây dựng, cá nhân đang tham gia
              trong ngành Thiết kế và Xây dựng (gọi tắt là Designer and
              Contractor – DnC) tại các công ty Thiết kế và Xây dựng. Tài khoản
              sẽ được Nippon Paint Việt Nam tạo và cung cấp cho người dùng.
            </p>
          </Panel>
          <Panel
            header={
              <span className="panel-title">
                2. Tôi có thể đăng nhập ứng dụng bằng cách nào?
              </span>
            }
            key="2"
          >
            <p>
              Quý khách hàng sử dụng số điện thoại và mật khẩu để đăng nhập ứng
              dụng. Nếu Quý khách hàng có bất kỳ thắc mắc nào xin vui lòng liên
              hệ với tổng đài hỗ trợ khách hàng qua đường dây nóng 1800 6111 để
              được hỗ trợ.
            </p>
          </Panel>
          <Panel
            header={
              <span className="panel-title">
                3. Tôi có thể đăng nhập tài khoản vào nhiều thiết bị không?
              </span>
            }
            key="3"
          >
            <p>
              Hiện tại, vì bảo mật thông tin của Quý khách hàng, ứng dụng Nippon
              DnC chỉ có thể đăng nhập một lần duy nhất trên một máy, do đó
              không thể đăng nhập vào máy khác cùng một lúc.
            </p>
          </Panel>
          <Panel
            header={
              <span className="panel-title">
                4. Tôi có thể xem được phiên bản ứng dụng hiện tại của tôi ở
                đâu?
              </span>
            }
            key="4"
          >
            <p>
              Trên màn hình chính của ứng dụng, Quý khách nhấn vào chức năng
              “Thông tin” và chọn “Thông tin ứng dụng” sẽ có thông tin phiên bản
              Ứng dụng Quý khách đang sử dụng.
            </p>
          </Panel>
          <Panel
            header={
              <span className="panel-title">
                5. Tôi xem danh sách Nhà thầu DiDiDi đã kết nối với tôi ở đâu?
              </span>
            }
            key="5"
          >
            <p>
              Quý khách nhấn vào chức năng “Thông tin điểm thưởng” để xem danh
              sách Nhà Thầu DiDiDi kết nối cùng Quý khách hàng.
            </p>
          </Panel>
          <Panel
            header={
              <span className="panel-title">
                6. Tôi xem Thể lệ chương trình kết nối ở đâu?
              </span>
            }
            key="6"
          >
            <p>
              Quý khách hàng vui lòng chọn chức năng “Thông tin” trên trang chủ,
              sau đó chọn “Thể lệ chương trình” để xem Thể lệ chương trình kết
              nối.
            </p>
          </Panel>
          <Panel
            header={
              <span className="panel-title">
                7. Tôi có thể xem sản phẩm của Nippon Paint ở đâu?
              </span>
            }
            key="7"
          >
            <p>
              Ứng dụng Nippon DnC mang đến cho Quý khách hàng các thông tin, tài
              liệu kỹ thuật, hợp chuẩn hợp quy,.. của sản phẩm nhằm hỗ trợ tốt
              nhất cho công tác thiết kế, chỉ định, và dự thầu/báo giá công
              trình. Trên trang chủ ứng dụng, Quý khách hàng vui lòng chọn chức
              năng “Sản phẩm” để cập nhật thông tin.
            </p>
          </Panel>
          <Panel
            header={
              <span className="panel-title">
                8. Tôi có thể xem thông tin đại lý bán sản phẩm của Nippon Paint
                gần tôi không?
              </span>
            }
            key="8"
          >
            <p>
              Để tra cứu thông tin đại lý của Nippon Paint, Quý khách hàng vui
              lòng chọn chức năng “Đại lý” ở trang chủ của ứng dụng, sau đó dùng
              bộ lọc để tìm kiếm khu vực mong muốn.
            </p>
          </Panel>
          <Panel
            header={
              <span className="panel-title">9. Tôi có thể đổi quà không?</span>
            }
            key="9"
          >
            <p>
              Khi tích lũy điểm kết nối đủ điều kiện đổi quà, Quý khách hàng có
              thể chủ động đổi quà trực tiếp trên ứng dụng Nippon DnC.
            </p>
          </Panel>
          <Panel
            header={
              <span className="panel-title">
                10. Tôi sử dụng quà như thế nào?
              </span>
            }
            key="10"
          >
            <p>
              Mỗi Voucher quà tặng cung cấp đến Quý khách hàng sẽ kèm theo thông
              tin hướng dẫn và thời gian sử dụng. Quý khách hàng vui lòng tham
              khảo thông tin chi tiết sau khi đổi quà.
            </p>
          </Panel>
        </Collapse>
      </div>
      <Footer />
    </div>
  );
}

import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import "./style.css";

export default function Header(props) {
  const actionBack = (e) => {
    e.stopPropagation();
    document.removeEventListener("message", () => {}, true);
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage("EXIT_COMMAND");
  };

  return (
    <div className="top-header">
      <div className="header-content">
        <LeftOutlined className="header-back" onClick={actionBack} />
        <p className="header-title">{props.title}</p>
      </div>
    </div>
  );
}

import React, { Fragment } from "react";
import { Collapse } from "antd";
import "../style.css";

const { Panel } = Collapse;
const listKeys = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
export default function TermContent({ open }) {
  return (
    <Fragment>
      {open && <p className="title-term">Điều khoản điều lệ</p>}
      <p>
        Vui lòng đọc kỹ Thỏa Thuận Sử Dụng (sau đây gọi tắt là “Thỏa Thuận”)
        trước khi thành viên tiến hành tải, cài đặt, sử dụng tất cả hoặc bất kỳ
        phần nào của ứng dụng “DnC” (sau đây gọi tắt là “Ứng Dụng”) (bao gồm
        nhưng không giới hạn phần mềm, các file và các tài liệu liên quan) hoặc
        sử dụng các dịch vụ do Công ty TNHH Nippon Paint (Việt Nam) (sau đây gọi
        là Nippon Paint Việt Nam) cung cấp để kết nối đến Ứng Dụng. Bằng việc
        bấm vào nút “Đồng Ý”, thành viên chấp thuận và đồng ý bị ràng buộc bởi
        các quy định và điều kiện trong Thỏa Thuận này khi thực hiện các thao
        tác trên đây. Trường hợp thành viên không đồng ý với bất kỳ điều khoản
        sử dụng nào của chúng tôi (phiên bản này và các phiên bản cập nhật),
        thành viên vui lòng không tải, cài đặt, sử dụng Ứng dụng hoặc tháo gỡ
        Ứng Dụng ra khỏi thiết bị di động của thành viên.
      </p>
      <Collapse
        bordered={false}
        collapsible={open ? "disabled" : true}
        defaultActiveKey={open ? listKeys : []}
        expandIconPosition="right"
        className="content"
      >
        <Panel
          showArrow={!open}
          header={<span className="panel-title">1. Cập nhật</span>}
          key="1"
        >
          <p>
            Thỏa Thuận này có thể được cập nhật thường xuyên bởi Nippon Paint
            Việt Nam, phiên bản cập nhật sẽ được chúng tôi công bố tại website
            (nipponpaint.com.vn). Phiên bản cập nhật sẽ thay thế cho các quy
            định và điều kiện trong thỏa thuận ban đầu. Thành viên có thể truy
            cập vào Ứng Dụng hoặc vào website trên đây để xem nội dung chi tiết
            của phiên bản cập nhật.
          </p>
        </Panel>
        <Panel
          showArrow={!open}
          header={
            <span className="panel-title">2. Giới thiệu về Ứng Dụng</span>
          }
          key="2"
        >
          <p>
            DnC là ứng dụng chăm sóc khách hàng của Nippon Paint Việt Nam dành
            riêng cho Kiến trúc sư, Thiết kế nội thất, chủ công ty xây dựng. Ứng
            dụng hỗ trợ cho Thành viên (1) Tham gia vào chương trình kết nối
            cùng nhà Thầu DiDiDi của chương trình “DiDiDi – Đối tác bền lâu; (2)
            Xem và tải tài liệu hồ sơ dự thầu cho các sản phẩm sơn của Nippon
            Paint Việt Nam; (3) Tra cứu thông tin sản phẩm; (4) Tra cứu thông
            tin Thành viên cung cấp sản phẩm sơn Nippon Paint (Việt Nam). <br />
            Ứng dụng hỗ trợ tất cả các thiết bị thông minh có nền tảng Android,
            IOS.
          </p>
        </Panel>
        <Panel
          showArrow={!open}
          header={<span className="panel-title">3. Quyền sở hữu Ứng Dụng</span>}
          key="3"
        >
          <p>
            Ứng Dụng này được phát triển và sở hữu bởi Nippon Paint Việt Nam,
            tất cả các quyền sở hữu trí tuệ liên quan đến Ứng Dụng (bao gồm
            nhưng không giới hạn mã nguồn, hình ảnh, dữ liệu, thông tin, nội
            dung chứa đựng trong Ứng Dụng; các sửa đổi, bổ sung, cập nhật của
            Ứng Dụng) và các tài liệu hướng dẫn liên quan (nếu có) sẽ thuộc
            quyền sở hữu duy nhất bởi Nippon Paint Việt Nam và không cá nhân, tổ
            chức nào được phép sao chép, tái tạo, phân phối, hoặc hình thức khác
            xâm phạm tới quyền của chủ sở hữu nếu không có sự đồng ý và cho phép
            bằng văn bản của Nippon Paint Việt Nam.
          </p>
        </Panel>
        <Panel
          showArrow={!open}
          header={<span className="panel-title">4. Tài khoản</span>}
          key="4"
        >
          <p>
            Để sử dụng Ứng dụng, Thành viên cần đăng ký bằng cách gửi thông tin
            cho chúng tôi bằng cách gọi điện thoại vào số hotline 18006111, hoặc
            liên hệ nhân viên chăm sóc khách hàng của Nippon Paint Việt Nam để
            được hướng dẫn đăng ký.
            <br />
            Thành viên cam kết rằng việc sử dụng tài khoản phải tuân thủ các quy
            định của Nippon Paint Việt Nam, đồng thời tất cả các thông tin Thành
            viên cung cấp cho chúng tôi là đúng, chính xác, đầy đủ tại thời điểm
            được yêu cầu. Mọi quyền lợi và nghĩa vụ của Thành viên sẽ căn cứ
            trên thông tin tài khoản đã đăng ký, do đó nếu có bất kỳ thông tin
            sai lệch nào chúng tôi sẽ không chịu trách nhiệm trong trường hợp
            thông tin đó làm ảnh hưởng hoặc hạn chế quyền lợi của Thành viên.
            <br />
            Định nghĩa và Giải thích từ ngữ:
            <ul>
              <li>
                Thành viên: là Kiến trúc sư, Thiết kế nội thất, Chủ công ty xây
                dựng có sử dụng/chỉ định sơn Nippon và kết nối cùng Thầu thợ
                DiDiDi của chương trình tích điểm “DiDiDi – Đối tác bền lâu”.
              </li>
              <li>
                Đăng nhập: Thành viên nhập thông tin số điện thoại/tài khoản đã
                được đăng ký và mật khẩu được cung cấp, sau đó nhấn “Đăng nhập”
                để vào Ứng dụng. Đối với lần đăng nhập đầu tiên, Ứng dụng sẽ yêu
                cầu Thành viên đổi mật khẩu để đảm bảo tính bảo mật.
              </li>
            </ul>
          </p>
        </Panel>
        <Panel
          showArrow={!open}
          header={
            <span className="panel-title">5. Quy định sử dụng Ứng dụng</span>
          }
          key="5"
        >
          <p>
            Thành viên có quyền sử dụng Ứng Dụng và các dịch vụ khác mà chúng
            tôi cung cấp, tuy nhiên việc sử dụng đó sẽ không bao gồm các hành vi
            sau đây nếu không có sự đồng ý bằng văn bản của Nippon Paint Việt
            Nam:
          </p>
          <ul className="term-list">
            <li>
              Sao chép, chỉnh sửa, tái tạo, tạo ra sản phẩm mới hoặc phiên bản
              phát sinh trên cơ sở Ứng Dụng này;
            </li>
            <li>
              Bán, chuyển giao, cấp quyền lại, tiết lộ hoặc hình thức chuyển
              giao khác hoặc đưa một phần hoặc toàn bộ Ứng Dụng cho bất kỳ bên
              thứ ba;
            </li>
            <li>
              Sử dụng Ứng Dụng để cung cấp dịch vụ cho bất kỳ bên thứ ba (tổ
              chức, cá nhân);
            </li>
            <li>
              Di chuyển, xóa bỏ, thay đổi bất kỳ thông báo chính đáng hoặc dấu
              hiệu nào của Ứng Dụng (bao gồm nhưng không giới hạn các tuyên bố
              về bản quyền);
            </li>
            <li>
              Thiết kế lại, biên dịch, tháo gỡ, chỉnh sửa, đảo lộn thiết kế của
              Ứng Dụng hoặc nội dung Ứng Dụng;
            </li>
            <li>Thay đổi hoặc hủy bỏ trạng thái ban đầu của Ứng Dụng;</li>
            <li>
              Sử dụng Ứng Dụng để thực hiện bất kỳ hành động gây hại cho hệ
              thống an ninh mạng của Nippon Paint Việt Nam, bao gồm nhưng không
              giới hạn sử dụng dữ liệu hoặc truy cập vào máy chủ hệ thống hoặc
              tài khoản không được phép; truy cập vào hệ thống mạng để xóa bỏ,
              chỉnh sửa và thêm các thông tin; phát tán các chương trình độc
              hại, virus hoặc thực hiện bất kỳ hành động nào khác nhằm gây hại
              hoặc phá hủy hệ thống mạng;
            </li>
            <li>
              Đăng nhập và sử dụng Ứng Dụng bằng một phần mềm tương thích của
              bên thứ ba hoặc hệ thống không được phát triển, cấp quyền hoặc
              chấp thuận bởi Nippon Paint Việt Nam;
            </li>
            <li>
              Sử dụng, bán, cho mượn, sao chép, chỉnh sửa, kết nối tới, phiên
              dịch, phát hành, công bố các thông tin liên quan đến Ứng Dụng, xây
              dựng mirror website để công bố các thông tin này hoặc để phát
              triển các sản phẩm phái sinh, công việc hoặc dịch vụ;
            </li>
            <li>
              Sử dụng Ứng Dụng hoặc các dịch vụ khác được cung cấp bởi Nippon
              Paint Việt Nam trong bất kỳ hình thức vi phạm pháp luật nào, cho
              bất kỳ mục đích bất hợp pháp nào;
            </li>
            <li>Các hình thức vi phạm khác…</li>
          </ul>
        </Panel>
        <Panel
          showArrow={!open}
          header={
            <span className="panel-title">
              6. Quy định về việc sử dụng tài khoản trên Ứng dụng
            </span>
          }
          key="6"
        >
          <p>6.1. Quyền và trách nhiệm của Thành viên</p>
          <ul className="term-list">
            <li>
              6.1.1 Thành viên cam kết đọc kỹ các điều kiện, điều khoản sử dụng
              ứng dụng trên điện thoại trước khi tải và sử dụng ứng dụng.
            </li>
            <li>
              6.1.2 Thành viên phải ngay lập tức thông báo cho Nippon Paint Việt
              Nam về bất kỳ sự thay đổi nào liên quan đến thông tin của Thành
              viên, bao gồm cả những thay đổi về địa chỉ và các chi tiết liên
              lạc khác hoặc bất cứ thông tin nào khác mà Nippon Paint Việt Nam
              yêu cầu vào từng thời điểm.
            </li>
            <li>
              6.1.3 Thành viên sẽ chịu trách nhiệm quản lý tài khoản, mật khẩu
              tài khoản, các thông tin liên quan… của mình.
            </li>
          </ul>
          <p>6.2. Quyền và trách nhiệm của Nippon Paint Việt Nam</p>
          <ul className="term-list">
            <li>
              6.2.1 Nippon Paint Việt Nam có quyền khoá tài khoản của Thành viên
              trong các trường hợp sau:
              <p>
                a&#41; Mọi hành vi bao gồm và không loại trừ như thông đồng giữa
                các Thành viên và nhà thầu DiDiDi để trục lợi, những hành vi
                không công bằng gây thiệt hại đến kinh tế cho Nippon Paint và
                các Thành viên khác <br />
                b&#41; Thành viên không hợp tác với Nippon Paint Việt Nam như
                không phản hồi tin nhắn và cuộc gọi. <br />
                c&#41; Thành viên có các hành vi như đã nêu ở mục 5 <br />
                d&#41; …vv
              </p>
            </li>
            <li>
              6.2.2 Nippon Paint Việt Nam được quyền sử dụng, lưu trữ, cung cấp
              hoặc trao đổi thông tin về Thành viên, giao dịch kết nối điểm hay
              các giao dịch khác nhằm mục đích phục vụ đảm bảo quyền lợi Thành
              viên được tốt hơn.
            </li>
            <li>
              6.2.3 Nippon Paint Việt Nam sẽ tư vấn và trợ giúp Thành viên khi
              Thành viên cần hỗ trợ.
            </li>
          </ul>
          <p>
            6.3. Bất khả kháng <br />
            Bất khả kháng bao gồm các sự kiện như: Thiên tai, chiến tranh, các
            trường hợp phát sinh lỗi từ ứng dụng. Khi xảy ra sự kiện bất khả
            kháng, hai bên sẽ tạm ngưng việc thực hiện theo hợp đồng này. Nippon
            Paint Việt Nam sẽ thực hiện việc thông báo cho Thành vien. Sau khi
            chấm dứt sự kiện bất khả kháng, hai bên sẽ tiếp tục thực hiện các
            nghĩa vụ theo bản thỏa thuận của chương trình này.
          </p>
          <p>6.4. Quy định khác</p>
          <ul className="term-list">
            <li>
              6.4.1 Nippon Paint Việt Nam có quyền sửa đổi, bổ sung nội dung
              điều khoản và điều kiện bằng cách thông báo trước khi có hiệu lực
              cho quý Thành viên.
            </li>
            <li>
              6.4.2 Nippon Paint Việt Nam sẽ thiết lập duy trì đường dây nóng đề
              tiếp nhận, xử lý kịp thời các phản ánh từ Thành viên. Thành viên
              có thể liên lạc với Nippon Paint Việt Nam qua đường dây nóng hoàn
              toàn miễn phí 1800 6111 hoặc liên hệ chăm sóc khách hàng để được
              hỗ trợ tư vấn, giải đáp thắc mắc.
            </li>
            <li>
              6.4.3 Trong trường hợp có tranh chấp giữa Thành viên và Nippon
              Paint Việt Nam, quyết định của công ty Nippon Paint Việt Nam là
              quyết định cuối cùng.
            </li>
          </ul>
        </Panel>
        <Panel
          showArrow={!open}
          header={
            <span className="panel-title">
              7. Xử lý vi phạm can thiệp ứng dụng
            </span>
          }
          key="7"
        >
          <p>
            Trường hợp Thành viên vi phạm bất kỳ quy định nào trong Thỏa Thuận
            này, Nippon Paint Việt Nam có quyền ngay lập tức khóa tài khoản của
            Thành viên và/hoặc xóa bỏ toàn bộ các thông tin, nội dung vi phạm,
            đồng thời tùy thuộc vào tính chất, mức độ vi phạm Thành viên sẽ phải
            chịu trách nhiệm trước điều kiện xử lý vi phạm của Nippon Paint Việt
            Nam.
          </p>
        </Panel>
        <Panel
          showArrow={!open}
          header={
            <span className="panel-title">
              8. Quyền truy cập và thu thập thông tin
            </span>
          }
          key="8"
        >
          <p>
            (a) Khi sử dụng Ứng Dụng, Thành viên thừa nhận rằng chúng tôi có
            quyền sử dụng những API hệ thống sau để truy cập vào dữ liệu trên
            điện thoại của Thành viên: (1) Đọc và ghi vào Kho Thư Viện Ảnh; (2)
            Lấy vị trí hiện tại của Thành viên khi được sự đồng ý; (3) Ghi dữ
            liệu của Ứng Dụng lên thẻ nhớ; (4) Truy cập vào Internet từ thiết bị
            của Thành viên; (5) Truy cập và sử dụng camera từ thiết bị của thành
            viên. Tất cả các truy cập này đều được chúng tôi thực hiện sau khi
            có sự đồng ý của Thành viên, vì vậy Thành viên cam kết và thừa nhận
            rằng, khi Thành viên đã cấp quyền cho chúng tôi, Thành viên sẽ không
            có bất kỳ khiếu nại nào đối với Nippon Paint Việt Nam về việc truy
            cập này.
          </p>
          <p>
            (b) Cùng với quyền truy cập, chúng tôi sẽ thu thập các thông tin sau
            của Thành viên: Thông tin cá nhân: Bao gồm các thông tin Thành viên
            cung cấp cho chúng tôi để xác nhận tài khoản như tên, số điện thoại,
            số chứng minh nhân dân, địa chỉ email; Thông tin chung: Như các
            thông tin về cấu hình điện thoại của Thành viên, thông tin phiên bản
            Ứng dụng mà Thành viên đang sử dụng cho điện thoại của mình; Thông
            tin vị trí của Thành viên: Dữ liệu về vị trí địa lý của Thành viên
            sẽ được lưu trữ trên máy chủ nhằm giúp Thành viên sử dụng chức năng
            tìm kiếm của Ứng Dụng; Kho Thư Viện Ảnh: Chúng tôi sẽ lưu trữ ảnh
            điện thoại của Thành viên trên máy chủ nhằm hỗ trợ tốt nhất cho
            Thành viên trong việc sử dụng Ứng Dụng và tránh trường hợp Thành
            viên bị mất dữ liệu. Chúng tôi cam kết sẽ tôn trọng và không sử dụng
            ảnh của Thành viên vì bất kỳ mục đích nào nếu không có sự đồng ý của
            Thành viên; Chúng tôi không sử dụng bất kỳ biện pháp nào để theo dõi
            nội dung tin nhắn, trao đổi hoặc hình thức khác nhằm theo dõi người
            dùng khi sử dụng Ứng Dụng này.
          </p>
        </Panel>
        <Panel
          showArrow={!open}
          header={
            <span className="panel-title">9. Cam kết bảo mật thông tin</span>
          }
          key="9"
        >
          <p>
            Nippon Paint Việt Nam sử dụng các phương thức truyền tin an toàn
            https và mã hóa để truyền tải và lưu trữ các dữ liệu cá nhân và giao
            tiếp của Thành viên. Chúng tôi cam kết giữ bí mật tất cả thông tin
            mà Thành viên cung cấp cho Nippon Paint Việt Nam hoặc chúng tôi thu
            thập từ Thành viên và không tiết lộ với bất kỳ bên thứ ba nào trừ
            khi có yêu cầu từ Cơ quan Nhà nước có thẩm quyền.
          </p>
        </Panel>
        <Panel
          showArrow={!open}
          header={<span className="panel-title">10. Phí và các khoản thu</span>}
          key="10"
        >
          <p>
            Nippon Paint Việt Nam cam kết không thu bất cứ khoản phí nào từ
            người dùng cho các dịch vụ cơ bản mà hiện tại chúng tôi đang cung
            cấp (trừ các khoản thu được quy định bởi nhà nước CHXHCN Việt Nam)
          </p>
        </Panel>
        <Panel
          showArrow={!open}
          header={<span className="panel-title">11. Quảng cáo</span>}
          key="11"
        >
          <p>
            Khi sử dụng Ứng dụng, (1) Thành viên thừa nhận rằng chúng tôi có
            quyền sử dụng các thông tin không định danh của Thành viên nhằm cung
            cấp các nội dung quảng cáo đúng đối tượng; (2) Thành viên có trách
            nhiệm đọc kỹ đồng thời tuân thủ chặt chẽ bản điều lệ này.
          </p>
        </Panel>
        <Panel
          showArrow={!open}
          header={
            <span className="panel-title">12. Liên Lạc Với Chúng Tôi</span>
          }
          key="12"
        >
          <p>
            Công Ty TNHH Nippon Paint (Việt Nam): Số 14, Đường 3A, KCN Biên Hòa
            II, Phường Long Bình Tân, TP Biên Hòa, Tỉnh Đồng Nai, Việt Nam.
            <br />
            Tel (84) 251 383 6579 – 383 6586 (8 lines). <br />
            Fax (84) 251 3836346 – 3836349. <br />
            Đường dây nóng: 1800 6111. <br />
            Trân trọng cảm ơn Thành viên đã sử dụng sản phẩm và dịch vụ của
            chúng tôi.
          </p>
        </Panel>
      </Collapse>
    </Fragment>
  );
}

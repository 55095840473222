import "./App.css";
import LoadingComponent from "./components/LoadingComponent";
import { useEffect, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TermCondition from "./containers/term/TermCondition";
import Questions from "./containers/questions/Questions";
import RuleCondition from "./containers/rules/TermCondition";

function App() {
  useEffect(() => {
    if ("addEventListener" in document) {
      document.addEventListener("DOMContentLoaded", function () {}, false);
    }
    //For iOS
    var iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      window.addEventListener(
        "message",
        function (e) {
          if (e.data !== "BACK_COMMAND") {
            let token = e.data;
            localStorage.setItem("token", token);
          }
        },
        false
      );
    } else {
      //For Android
      document.addEventListener(
        "message",
        function (e) {
          if (e.data !== "BACK_COMMAND") {
            let token = e.data;
            localStorage.setItem("token", token);
          }
        },
        false
      );
    }
  }, []);
  return (
    <div className="App">
      <Suspense fallback={<LoadingComponent />}>
        <Switch>
          <Route exact path="/term" component={TermCondition} />
          <Route exact path="/rules" component={RuleCondition} />
          <Route exact path="/bay" component={TermCondition} />
          <Route exact path="/faq" component={Questions} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
